<template>
    <div class="page-warp min-page gray-page">
        <van-address-edit
            :detail-rows="4"
            :show-area="false"
            @save="onSave"
            />
    </div>
</template>
<script>
import { userAddressAdd } from "@/api/user";
import { checkPhone } from '../../assets/js/comm'
export default {
    name:'addressAdd',
    data() {
        return {
            fromPage:'',
        };
    },
    created() {
        this.fromPage = this.$route.query.from;
    },
    methods: {
        async onSave(content) {
            // if(!content.name){
            //     this.$toast.fail('姓名不能为空');
            //     return false;
            // }
            // if(!content.tel){
            //     this.$toast.fail('电话不能为空');
            //     return false;
            // }else if(checkPhone(content.tel)){
            //     this.$toast.fail('电话格式不正确');
            //     return false;
            // }
            // if(!content.addressDetail){
            //     this.$toast.fail('详细地址不能为空');
            //     return false;
            // }
            let where = {
                recipientName:content.name,
                recipientPhone:content.tel,
                recipientAddress:content.addressDetail,
            }
            let res = await userAddressAdd(where);

            this.$toast.success('操作成功');
            this.$router.push({
                path:'/addressList',
                query:{
                    from:this.fromPage
                }
            })
        },
    },
};
</script>
<style lang="less" scope>
</style>